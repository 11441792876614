import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import {Link, useIntl } from "gatsby-plugin-intl"
import MyDealer from "../dealer/my-dealer";


class BodyClass extends React.Component {
  // static propTypes = {
  //   name: React.PropTypes
  // }
  // static defaultProps = {
  //   name: ''
  // }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const MyDealerPage =({location}) => {
  const intl = useIntl();

  const pageContent = (
    <div className="col-lg-8">

    <MyDealer location={location} navigateTo={"/profile/choose-dealer"}></MyDealer>

    </div>
  )


  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.my-dealer" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent} currentPage="my-dealer"/>

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )

}

export default MyDealerPage
